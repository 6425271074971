import { rxapi, api } from '../api';
import { mapStrategyPlanToIndicatorFormulaIfPossible } from '../if-then/if-then-helpers';
import { getImportsFromRefs } from '../lib/dictionary/dictionary';
import { processQuery } from './paging';
import { client } from './table';
import type { AxiosResponse } from 'axios';
import type { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import type { IndicatorImportViewModel } from 'src/contracts/dictionary-view-model';
import type { ResourceQuery, ResourceQueryResponseWithMeta } from 'src/contracts/resource-query';
import type {
    ConcreteStrategy,
    NewStrategy,
    NewStrategyInstance,
    Strategy,
    StrategyInstance,
    StrategyPatch,
    StrategyRecord,
    StrategyWithVersions,
    TopStrategies,
} from 'src/contracts/strategy';
import type { CreateTablePayload, TableCreationResult } from 'src/contracts/table';
import { container } from 'src/ioc/StaticContainer';
import type { ResearchStrategy } from 'src/models/strategy';

const _log = container.get('Logger').getSubLogger({ name: 'strategy-service' });

export const getStrategyComplexityErrors = async (
    strategy: ResearchStrategy<IndicatorImportViewModel>,
): Promise<{ message: string }[]> => {
    return (await api.post(`/strategies/complexity-errors`, strategy)).data;
};

export const getStrategyRecordsQuery = async (
    query: ResourceQuery,
): Promise<ResourceQueryResponseWithMeta<StrategyRecord>> => {
    return (await api.get(`/strategies/query${processQuery(query)}`)).data;
};
export const getStrategyRecordsCountQuery = async (): Promise<ResourceQueryResponseWithMeta<StrategyRecord>> => {
    return (await api.get(`/strategies/query?$select=&$count=true`)).data;
};

export function getAllStrategyRecords(): Observable<StrategyRecord[]> {
    return rxapi.get<StrategyRecord[]>('/strategies/records/all').pipe(map((x) => x.data));
}

export async function getStrategyRecordsByIds(ids: string[]): Promise<StrategyRecord[]> {
    return (
        await api.get<StrategyRecord[]>('/strategies/records', {
            params: { ids },
        })
    ).data;
}

export function getTopStrategyRecords(): Observable<TopStrategies> {
    return rxapi.get<TopStrategies>('/strategies/top').pipe(map((x) => x.data));
}

export async function getStrategyById(id: string): Promise<ConcreteStrategy> {
    return (await api.get<ConcreteStrategy>(`/strategies/${id}`)).data;
}

export async function getStrategyByIdWithImports(id: string): Promise<ConcreteStrategy<IndicatorImportViewModel>> {
    const strategy = await getStrategyById(id);
    const imports = await getImportsFromRefs(strategy.plan.imports);

    const strategyWithImports: ConcreteStrategy<IndicatorImportViewModel> = {
        ...strategy,
        plan: {
            ...strategy.plan,
            imports,
        },
    };

    return strategyWithImports;
}

export function getStrategyVersion(
    id: string,
    version: number,
    expandImports: true,
): Observable<ConcreteStrategy<IndicatorImportViewModel> | undefined>;
export function getStrategyVersion(
    id: string,
    version: number,
    expandImports?: false,
): Observable<ConcreteStrategy | undefined>;
export function getStrategyVersion(
    id: string,
    version: number,
    expandImports?: boolean,
): Observable<ConcreteStrategy | undefined> {
    return rxapi
        .get<ConcreteStrategy>(`/strategies/${id}/${version}${expandImports ? '?$expand=imports' : ''}`)
        .pipe(map((x) => x.data));
}

export const getStrategiesByIds = async (ids: string[]): Promise<ConcreteStrategy[]> =>
    (await api.get(`strategies`, { params: { ids } })).data;

export const getStrategiesWithVersionsByIds = async (
    ids: string[],
    expandImports = false,
): Promise<StrategyWithVersions[]> =>
    (
        await api.get(`strategies/versions`, {
            params: { ids, ...(expandImports ? { $expand: 'imports' } : {}) },
        })
    ).data;

export function deleteStrategyById(id: string): Promise<AxiosResponse> {
    return api.delete(`/strategies/${id}`);
}

export function createStrategy(
    strategy: NewStrategy,
    expandImports: true,
): Observable<ConcreteStrategy<IndicatorImportViewModel>>;
export function createStrategy(strategy: NewStrategy): Observable<ConcreteStrategy>;
export function createStrategy(strategy: NewStrategy, expandImports?: true): Observable<ConcreteStrategy> {
    const strategyWithMappedPlan = { ...strategy, plan: mapStrategyPlanToIndicatorFormulaIfPossible(strategy.plan) };
    return rxapi
        .post<ConcreteStrategy>(`/strategies${expandImports ? '?$expand=imports' : ''}`, strategyWithMappedPlan)
        .pipe(map((x) => x.data));
}

export function patchStrategy(id: string, patch: StrategyPatch): Observable<ConcreteStrategy> {
    if (patch.plan) patch.plan = mapStrategyPlanToIndicatorFormulaIfPossible(patch.plan);
    return rxapi.patch<ConcreteStrategy>(`/strategies/${id}`, patch).pipe(map((x) => x.data));
}

export function playStrategy({
    includeTags,
    ...newStrategyInstance
}: NewStrategyInstance & { includeTags?: boolean }): Observable<StrategyInstance> {
    const includeTagsQuery = includeTags ? `?includeTags=${includeTags}` : '';
    const res = rxapi.post<StrategyInstance>(`/strategies/instances${includeTagsQuery}`, newStrategyInstance).pipe(
        map((x) => x.data),
        tap(({ tableCookie }) => {
            if (tableCookie) {
                client.addAccessCookie(tableCookie);
            }
        }),
    );
    return res;
}

export function idOfStrategy(strategy: string | Strategy | StrategyRecord): string | undefined {
    if (typeof strategy === 'string') return strategy;
    else return strategy.id;
}

/** @deprecated use createTable from services/table */
export async function submitTablePayload(payload: CreateTablePayload): Promise<TableCreationResult> {
    return await api.post<TableCreationResult>(`/tables`, payload).then((x) => x.data);
}
