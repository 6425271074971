import type { NaturalLanguageAtomState } from '../../types/naturalLanguageAtomState';
import type { CodeEditorResult } from 'src/features/code-editor';
import type { NlpParseResponseData, NlpParseResponseError, NlpSuggestion } from 'src/lib/nlp/schema';
import { IndicatorImportRefModel } from 'src/models/IndicatorImportRef';

export const handleNaturalLanguageChange = (
    state: NaturalLanguageAtomState,
    naturalLanguage: string,
): NaturalLanguageAtomState => {
    const nextStateAtom: NaturalLanguageAtomState['atom'] = {
        status: 'writing' as const,
    };

    if (state.atom.status === 'confirmed') {
        nextStateAtom.previouslyConfirmedFormula = state.atom.confirmedFormula;
    }

    return {
        naturalLanguage,
        displayMode: state.displayMode,
        atom: nextStateAtom,
    };
};

export const handleAcceptSuggestion = (
    state: NaturalLanguageAtomState,
    suggestion: NlpSuggestion,
): NaturalLanguageAtomState => {
    return handleNaturalLanguageChange(state, suggestion.input);
};

export const handleCodeChange = (
    state: NaturalLanguageAtomState,
    result: CodeEditorResult,
): NaturalLanguageAtomState => {
    if (result.code === '' && state.atom.status !== 'user_code') {
        return state;
    }

    if (state.atom.status === 'confirmed' && result.code === state.atom.confirmedFormula) {
        return state;
    }

    return {
        naturalLanguage: '',
        displayMode: state.displayMode,
        atom: {
            status: 'user_code' as const,
            errors: result.errors,
            result: result.result,
            code: result.code,
            previousNaturalLanguageAtomState:
                state.atom.status === 'user_code' ? state.atom.previousNaturalLanguageAtomState : state,
        },
    };
};

export const handleSetMode = (
    state: NaturalLanguageAtomState,
    displayMode: NaturalLanguageAtomState['displayMode'],
): NaturalLanguageAtomState => {
    return {
        ...state,
        displayMode,
    };
};

export const hasErrors = (state: NaturalLanguageAtomState) => {
    if (state.atom.status === 'errored') {
        return true;
    }

    if (state.atom.status === 'user_code' && state.atom.code !== '' && Boolean(state.atom.errors?.length)) {
        return true;
    }

    return false;
};

export const handleParsedResponse = (
    state: NaturalLanguageAtomState,
    response: NlpParseResponseData,
): NaturalLanguageAtomState => {
    return {
        ...state,
        atom: {
            status: 'parsed' as const,
            unconfirmedFormula: {
                formula: response.result.formula,
                aliases: response.result.imports.map((ref) =>
                    IndicatorImportRefModel.fromIndicatorImportRefViewModel(ref),
                ),
            },
        },
    };
};

export const handleParsedResponseError = (
    state: NaturalLanguageAtomState,
    error: NlpParseResponseError,
): NaturalLanguageAtomState => {
    return {
        ...state,
        atom: {
            status: 'errored' as const,
            suggestions: error.detail.similar ?? [],
        },
    };
};

export const handleRejectParsedResponse = (state: NaturalLanguageAtomState): NaturalLanguageAtomState => {
    return {
        ...state,
        atom: {
            status: 'writing' as const,
        },
    };
};

export const handleEditParsedResponse = (state: NaturalLanguageAtomState): NaturalLanguageAtomState => {
    const { atom } = state;
    if (atom.status === 'parsed') {
        return {
            ...state,
            atom: {
                status: 'user_code' as const,
                code: atom.unconfirmedFormula.formula,
                previousNaturalLanguageAtomState: state,
            },
        };
    }

    return state;
};

export const handleConfirmParsedResponse = (
    state: NaturalLanguageAtomState,
    confirmedFormula: string,
): NaturalLanguageAtomState => {
    const { atom } = state;
    if (atom.status === 'parsed') {
        return {
            ...state,
            atom: {
                status: 'confirmed' as const,
                confirmedFormula,
            },
        };
    }

    return state;
};
