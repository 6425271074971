import { getIndicators } from '../../lib/dictionary/dictionary';
import { client } from '../../lib/table';
import { useSelector } from '../../store/hooks';
import { EmptyArray } from '@thinkalpha/common/util/iterators.js';
import { useUserQuery } from 'src/hooks/useUserQuery';
import type { RootState } from 'src/store';

const selectUser = (state: RootState) => state.auth.masqUser ?? state.auth.user;
export const useUser = () => useSelector(selectUser);

const selectAccessToken = (state: RootState) => state.auth.masqAccessToken ?? state.auth.accessToken;
export const useAccessToken = () => useSelector(selectAccessToken);

export const useClient = () => {
    return client;
};

export const useGetIndicators = () => {
    const { data } = useUserQuery({
        queryKey: ['indicators'],
        queryFn: () => getIndicators(),
        placeholderData: EmptyArray,
        meta: {
            persist: true,
        },
    });

    return data ?? EmptyArray;
};
