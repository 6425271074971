import type {
    IfThenFormulaLine,
    IfThenGroup,
    IfThenGuidedLine,
    IfThenLine,
    IfThenLineBase,
    IfThenPart,
    IfThenResearchPlan,
} from '../contracts/if-then';
import { IfThenFieldInView, IfThenLineMode, IfThenPartType } from '../contracts/if-then';
import type { IfThenStrategyPlan, Strategy } from '../contracts/strategy';
import { StrategyType } from '../contracts/strategy';
import type { ResearchStrategy } from '../models/strategy';
import { isIfThenResearchPlan } from '../models/strategy';
import { filterIndicatorImportsOrRefs, mapIndicatorToDictionaryFunctionDef } from '../util/dictionary';
import { randomString } from '../util/randomString';
import { isIfThenGroup, isIfThenLine } from './model';
import type { FormulaModel } from './useFormulaModel';
import { HashMap } from '@thinkalpha/common/util/hashMap.js';
import type {
    BinaryOperationNode,
    BinaryOperator,
    ComparisonOperator,
    ConcreteDataType,
    ConcreteUnit,
    KnownAstInfo,
    KnownAstNode,
    ParserResult,
} from '@thinkalpha/language-services';
import {
    analyzeNode,
    analyzer,
    AstNodeType,
    BooleanComparisonOperator,
    canConvert,
    categorizeOperator,
    compareNodesSemantically,
    FieldTypeCategory,
    getNameForDataType,
    iterateAst,
    LogicalOperator,
    NumericComparisonOperator,
    OperatorType,
    parser,
    render,
    replaceNodes,
    StringComparisonOperator,
} from '@thinkalpha/language-services';
import cloneDeep from 'lodash/cloneDeep';
import { EMPTY } from 'rxjs';
import type { AnalyzerFunction } from 'src/components/filter-editor/model';
import type {
    IndicatorFormulaViewModel,
    IndicatorImportViewModel,
    IndicatorImportRefViewModel,
} from 'src/contracts/dictionary-view-model';
import { fakeConstStringParserResult } from 'src/lib/parser';

export function createDefaultLine(mode: IfThenLineMode.formula, fieldInView?: IfThenFieldInView): IfThenFormulaLine;
export function createDefaultLine(mode: IfThenLineMode.guided, fieldInView?: IfThenFieldInView): IfThenGuidedLine;
export function createDefaultLine(mode: IfThenLineMode, fieldInView?: IfThenFieldInView): IfThenLine;
export function createDefaultLine(
    mode: IfThenLineMode,
    fieldInView: IfThenFieldInView = IfThenFieldInView.nlp,
): IfThenLine {
    if (mode === IfThenLineMode.guided) {
        const line: IfThenLine = {
            mode,
            type: IfThenPartType.line,
            enabled: true,
            id: randomString(),
            description: null,
            lhs: {
                formula: '',
                fieldInView,
            },
            operator: null,
            rhs: {
                formula: '',
                fieldInView,
            },
        };

        Object.freeze(line);
        return line;
    } else {
        const line: IfThenLine = {
            mode,
            type: IfThenPartType.line,
            enabled: true,
            id: randomString(),
            description: null,
            formula: '',
            fieldInView: fieldInView ?? IfThenFieldInView.formula,
        };

        Object.freeze(line);
        return line;
    }
}

export function createDefaultGroup(operator: LogicalOperator, lines: (IfThenGroup | IfThenLine)[]): IfThenGroup {
    const group: IfThenGroup = {
        id: randomString(),
        type: IfThenPartType.group,
        enabled: true,
        lines,
        operator,
        collapsed: false,
        description: null,
        name: null,
    };
    Object.freeze(group);
    return group;
}

export function createDefaultStrategy(
    mode: IfThenLineMode = IfThenLineMode.formula,
    fieldInView?: IfThenFieldInView,
): ResearchStrategy<IndicatorImportViewModel> {
    const strategy: ResearchStrategy<IndicatorImportViewModel> = {
        name: null,
        description: null,
        defaultUniverseId: null,
        defaultColumnTemplateId: null,
        isTemplate: false,
        type: StrategyType.formula,
        plan: {
            key: 'ticker',
            root: createDefaultGroup(LogicalOperator.or, [
                createDefaultGroup(LogicalOperator.and, [createDefaultLine(mode, fieldInView)]),
            ]),
            imports: [],
        },
    };
    Object.freeze(strategy);
    return strategy;
}

export function createResearchStrategyFromIndicatorFormula(
    indicatorFormula: IndicatorFormulaViewModel<IndicatorImportViewModel>,
): IfThenResearchPlan<'ticker', IndicatorImportViewModel> {
    return {
        key: 'ticker',
        root: {
            id: randomString(),
            type: IfThenPartType.group,
            name: null,
            description: null,
            enabled: true,
            collapsed: false,
            operator: LogicalOperator.or,
            lines: [
                {
                    id: randomString(),
                    type: IfThenPartType.group,
                    name: null,
                    description: null,
                    enabled: true,
                    collapsed: false,
                    operator: LogicalOperator.and,
                    lines: [
                        {
                            id: randomString(),
                            type: IfThenPartType.line,
                            mode: IfThenLineMode.formula,
                            fieldInView: IfThenFieldInView.formula,
                            enabled: true,
                            description: null,
                            formula: indicatorFormula.formula,
                        },
                    ],
                },
            ],
        },
        imports: indicatorFormula.imports,
    };
}

export class CallNodeValueMap extends HashMap<KnownAstNode, KnownAstNode> {
    constructor() {
        super(
            (node) => node.type,
            (a, b) => compareNodesSemantically(a, b, { ignoreParen: true }),
        );
    }
}

/**
 * Given a formula string, maps over each node in the formula string with a
 * replacer function, then returns the new formula string.
 *
 * @param formula a formula string
 * @param analyzers array of AnalyzerFunction[]; the analyzers provided will be
 * run on the parsed formula before the replacer is run, providing relevant nodes
 * with a functionDef property that is accessible in the replacer
 * @param replacer the replacer function will map over each node in the formula
 *
 * @returns a new formula string
 */
export function replaceFormulaNodes(
    formula: string,
    analyzers: AnalyzerFunction[],
    replacer: (node: KnownAstNode | null, parent: KnownAstNode | null) => KnownAstNode | null,
): string {
    const { root } = parse(formula, analyzers);

    // If no root exists, return original formula unchanged
    if (!root) return formula;

    const newRoot = replaceNodes(root, replacer);

    return render(newRoot);
}

export interface EnhancedTag {
    label: string;
    dataType: ConcreteDataType;
    unit: ConcreteUnit | null;
}

export type TagMap = Map<string, EnhancedTag>;

export interface EnhancedTagSet {
    planWithValidationTags: IfThenStrategyPlan;
    validationTags: TagMap;
}

export function addEnhancedTagsToPlan(plan: IfThenStrategyPlan): EnhancedTagSet {
    const validationTags: TagMap = new Map();

    const duplicateCheck: CallNodeValueMap = new CallNodeValueMap();
    const createTagId = () => randomString(undefined, true);

    function addTagsToOperand(operand: string): string {
        const { root } = parse(operand, [
            (x) => {
                analyzer(x, {
                    functionDefs: (plan.imports ? filterIndicatorImportsOrRefs(plan.imports) : []).map(
                        mapIndicatorToDictionaryFunctionDef,
                    ),
                });
                return EMPTY;
            },
        ]);

        if (root && root.parserResult && root.type !== AstNodeType.const && !duplicateCheck.get(root)) {
            const tagId = createTagId();

            validationTags.set(tagId, {
                label: root.parserResult.text,
                dataType: root.dataType ?? FieldTypeCategory.String,
                unit: root.unit ?? null,
            });

            duplicateCheck.set(root, root);

            const newRoot: KnownAstInfo = {
                type: AstNodeType.paren,
                content: root,
                tag: tagId,
            };
            return render(newRoot);
        } else {
            return operand;
        }
    }

    function addTagsToFormula(formula: string): string {
        const { root } = parse(formula, [
            (x) => {
                analyzer(x, {
                    functionDefs: (plan.imports ? filterIndicatorImportsOrRefs(plan.imports) : []).map(
                        mapIndicatorToDictionaryFunctionDef,
                    ),
                });
                return EMPTY;
            },
        ]);

        if (root) {
            const newRoot = replaceNodes(root, (node: KnownAstNode | null, parent: KnownAstNode | null) => {
                if (
                    node &&
                    node.type !== AstNodeType.const &&
                    parent?.type === AstNodeType.binaryOperation &&
                    categorizeOperator(parent.operator) === OperatorType.comparison
                ) {
                    const { start, end } = node.ranges.node;
                    const nodeAsString = node.parserResult?.text.substring(start, end) ?? '';

                    if (nodeAsString && !duplicateCheck.get(node)) {
                        const tagId = createTagId();

                        validationTags.set(tagId, {
                            label: nodeAsString,
                            dataType: node.dataType ?? FieldTypeCategory.String,
                            unit: node.unit ?? null,
                        });

                        duplicateCheck.set(node, node);

                        return {
                            type: AstNodeType.paren,
                            content: node,
                            tag: tagId,
                        };
                    }
                }
                return node;
            });

            const formulaWithTags = render(newRoot);

            return formulaWithTags;
        } else {
            return formula;
        }
    }

    function addTagsToLine<T extends IfThenPart>(line: T): T {
        if (isIfThenGroup(line)) {
            return { ...line, lines: line.lines.map(addTagsToLine) };
        } else if (isIfThenLine(line)) {
            if (line.mode === IfThenLineMode.formula) {
                if (!line.formula) return line;

                const newFormula = addTagsToFormula(line.formula);
                line.formula = newFormula;

                return line;
            } else {
                if (line.lhs.formula) {
                    const newLhs = addTagsToOperand(line.lhs.formula);
                    line.lhs.formula = newLhs;
                }

                if (line.rhs.formula) {
                    const newRhs = addTagsToOperand(line.rhs.formula);
                    line.rhs.formula = newRhs;
                }

                return line;
            }
        } else {
            return line;
        }
    }

    return {
        planWithValidationTags: isIfThenResearchPlan(plan)
            ? { ...plan, root: addTagsToLine(cloneDeep(plan.root)) }
            : { ...plan, formula: addTagsToFormula(cloneDeep(plan.formula)) },
        validationTags,
    };
}

export function addTagsToPlan<T extends IndicatorImportRefViewModel>(
    plan: IfThenStrategyPlan<T>,
): {
    planWithValidationTags: IfThenStrategyPlan<T>;
    validationColumnNameLookup: Map<string, string>;
} {
    const validationColumnNameLookup: Map<string, string> = new Map<string, string>();
    const duplicateCheck: CallNodeValueMap = new CallNodeValueMap();
    const createTagId = () => randomString(undefined, true);

    function addTagsToOperand(operand: string): string {
        const { root } = parser(operand, {});

        if (root && root.parserResult && root.type !== AstNodeType.const && !duplicateCheck.get(root)) {
            const tagId = createTagId();
            validationColumnNameLookup.set(tagId, root.parserResult.text);
            duplicateCheck.set(root, root);

            const newRoot: KnownAstInfo = {
                type: AstNodeType.paren,
                content: root,
                tag: tagId,
            };
            return render(newRoot);
        } else {
            return operand;
        }
    }

    function addTagsToFormula(formula: string): string {
        const { root } = parser(formula, {});

        if (root) {
            const newRoot = replaceNodes(root, (node: KnownAstNode | null, parent: KnownAstNode | null) => {
                if (
                    node &&
                    node.type !== AstNodeType.const &&
                    parent?.type === AstNodeType.binaryOperation &&
                    categorizeOperator(parent.operator) === OperatorType.comparison
                ) {
                    const { start, end } = node.ranges.node;
                    const nodeAsString = node.parserResult?.text.substring(start, end) ?? '';

                    if (nodeAsString && !duplicateCheck.get(node)) {
                        const tagId = createTagId();
                        validationColumnNameLookup.set(tagId, nodeAsString);
                        duplicateCheck.set(node, node);

                        return {
                            type: AstNodeType.paren,
                            content: node,
                            tag: tagId,
                        };
                    }
                }
                return node;
            });

            const formulaWithTags = render(newRoot);

            return formulaWithTags;
        } else {
            return formula;
        }
    }

    function addTagsToLine<T extends IfThenPart>(line: T): T {
        if (isIfThenGroup(line)) {
            return { ...line, lines: line.lines.map(addTagsToLine) };
        } else if (isIfThenLine(line)) {
            if (line.mode === IfThenLineMode.formula) {
                if (!line.formula) return line;

                const newFormula = addTagsToFormula(line.formula);
                line.formula = newFormula;

                return line;
            } else {
                if (line.lhs.formula) {
                    const newLhs = addTagsToOperand(line.lhs.formula);
                    line.lhs.formula = newLhs;
                }

                if (line.rhs.formula) {
                    const newRhs = addTagsToOperand(line.rhs.formula);
                    line.rhs.formula = newRhs;
                }

                return line;
            }
        } else {
            return line;
        }
    }

    return {
        planWithValidationTags: isIfThenResearchPlan(plan)
            ? { ...plan, root: addTagsToLine(cloneDeep(plan.root)) }
            : { ...plan, formula: addTagsToFormula(cloneDeep(plan.formula)) },
        validationColumnNameLookup,
    };
}

export function removeWrappingParentheses(string: string) {
    if (string[0] === '(' && string[string.length - 1] === ')') {
        return string.slice(1, -1);
    }

    return string;
}

/**
 * @deprecated Use FormulaService.parse instead
 */
export function parse(
    value: string,
    analyzers: AnalyzerFunction[],
    equalsMode = false,
    dataTypeRequired?: ConcreteDataType,
): ParserResult {
    if (equalsMode && !value.startsWith('=')) {
        return fakeConstStringParserResult(value);
    }
    const filterValue = equalsMode ? value.substr(1) : value;

    let parserResult = parser(filterValue ?? '', {});
    if (parserResult.root) {
        for (const analyzer of analyzers) analyzer(parserResult.root);
    }

    if (dataTypeRequired) {
        if (
            !parserResult.root ||
            !parserResult.root.dataType ||
            !canConvert(parserResult.root.dataType, dataTypeRequired)
        ) {
            const newError = {
                text:
                    `Expected type ${getNameForDataType(dataTypeRequired)}` +
                    (parserResult.root && parserResult.root.dataType
                        ? `, but instead found ${getNameForDataType(parserResult.root.dataType)}`
                        : ''),
                range: { start: 0, end: parserResult.text.length },
                source: 'if-then',
            };
            parserResult = { ...parserResult, errors: [...parserResult.errors, newError], valid: false };
        }
    }

    return parserResult;
}

export function isLogicalOperator(operator: BinaryOperator): operator is LogicalOperator {
    return operator in LogicalOperator;
}

export function isComparisonOperator(operator: BinaryOperator): operator is ComparisonOperator {
    return (
        operator in NumericComparisonOperator ||
        operator in StringComparisonOperator ||
        operator in BooleanComparisonOperator
    );
}

/**
 * Converts an KnownAstNode to a set of guided lines, transforming logical binary
 * comparisons found in the node structure into groups where necessary. Operates
 * recursively to convert nested nodes into their own groups or lines.
 *
 * For example, a node created from the formula
 * "(HealthCare == true) and (Pct_Change(BarClose(1d), [.1d|0]) > (0.05))" and
 * passed to convertNodeToGuidedLine will be split into one group that uses
 * operator "and" and contains two guided lines.
 *
 * @param node A node
 * @param lineBase Line details (id, description, and enabled) that will be applied
 * to the base, whether it is a group or line
 * @returns An group or guided line, depending on the topmost logical
 * operator
 */
export function convertNodeToGuidedLine(
    node: KnownAstNode,
    lineBase?: Pick<IfThenLineBase, 'id' | 'description' | 'enabled'>,
): IfThenGroup | IfThenGuidedLine {
    // If node is parenthetical, rerun function on content within parentheses
    if (node.type == AstNodeType.paren) return convertNodeToGuidedLine(node.content, lineBase);

    // If node is binary with operator and/or, convert node to a group
    if (node.type === AstNodeType.binaryOperation && isLogicalOperator(node.operator)) {
        const lines: (IfThenGroup | IfThenLine)[] = [];
        lines.push(convertNodeToGuidedLine(node.lhs));
        if (node.rhs) lines.push(convertNodeToGuidedLine(node.rhs));

        return { ...createDefaultGroup(node.operator, lines), ...lineBase };
    }

    // If node is binary with operator and/or, convert node to a line
    if (node.type === AstNodeType.binaryOperation && isComparisonOperator(node.operator)) {
        return {
            ...createDefaultLine(IfThenLineMode.guided, IfThenFieldInView.formula),
            ...lineBase,
            lhs: {
                formula: removeWrappingParentheses(render(node.lhs)),
                fieldInView: IfThenFieldInView.formula,
            },
            operator: node.operator,
            rhs: {
                formula: removeWrappingParentheses(render(node.rhs)),
                fieldInView: IfThenFieldInView.formula,
            },
            mode: IfThenLineMode.guided,
        };
    }

    // If node matches none of the criteria, use a default line and lose line content
    return { ...createDefaultLine(IfThenLineMode.guided, IfThenFieldInView.formula), ...lineBase };
}

export function convertFormulaLineToGuidedLine(line: IfThenFormulaLine): IfThenGroup | IfThenGuidedLine {
    const { formula, id, description, enabled } = line;
    const lineBase = { id, description, enabled };

    const { root } = formula ? parse(formula, []) : { root: undefined };

    if (root) {
        return convertNodeToGuidedLine(root, lineBase);
    } else {
        return { ...createDefaultLine(IfThenLineMode.guided, IfThenFieldInView.formula), ...lineBase };
    }
}

export function convertGuidedLineToFormulaLine(line: IfThenGuidedLine): IfThenFormulaLine {
    const { lhs, operator, rhs, ...rest } = line;

    // If any parts of the formula already exist, show new formula line with IfThenFieldInView.formula
    const fieldInView = lhs.formula?.length || rhs.formula?.length ? IfThenFieldInView.formula : IfThenFieldInView.nlp;

    return {
        ...rest,
        formula: `${lhs.formula ? `(${lhs.formula}) ` : ''}${operator ?? ''}${rhs.formula ? ` (${rhs.formula})` : ''}`,
        mode: IfThenLineMode.formula,
        fieldInView,
    };
}

export function mapStrategyPlanToResearchStrategyPlan(
    plan: IfThenStrategyPlan<IndicatorImportViewModel>,
): IfThenResearchPlan<'ticker', IndicatorImportViewModel> {
    return isIfThenResearchPlan(plan) ? plan : createResearchStrategyFromIndicatorFormula(plan);
}

export function mapStrategyToResearchStrategy(
    strategy: Strategy<IndicatorImportViewModel>,
): ResearchStrategy<IndicatorImportViewModel> {
    return { ...strategy, plan: mapStrategyPlanToResearchStrategyPlan(strategy.plan) };
}

export function mapStrategyPlanToIndicatorFormulaIfPossible(plan: IfThenStrategyPlan): IfThenStrategyPlan {
    if (
        isIfThenResearchPlan(plan) &&
        plan.root.lines.length === 1 &&
        plan.root.lines[0].type === IfThenPartType.group &&
        plan.root.lines[0].lines.length === 1 &&
        plan.root.lines[0].lines[0].type === IfThenPartType.line &&
        plan.root.lines[0].lines[0].mode === IfThenLineMode.formula &&
        plan.root.lines[0].lines[0].fieldInView === IfThenFieldInView.formula
    ) {
        return { formula: plan.root.lines[0].lines[0].formula ?? '', imports: plan.imports };
    } else {
        return plan;
    }
}

/**
 * Iterates through ParserResult and pulls errors from nodes into array.
 * @param parserResult the ParserResult
 * @returns an array of string errors
 */
export function getLineErrorsForParserResult(parserResult: ParserResult): string[] {
    const lineErrors: string[] = [];

    lineErrors.push(...parserResult.errors.map((x) => x.text));

    if (parserResult.root) {
        for (const node of iterateAst(parserResult.root)) {
            lineErrors.push(...node.errors.map((x) => x.text));
        }
    }

    return lineErrors;
}

/**
 * Generates incompatible return type errors for a guided line and combines, 
 * with internal node errors for operands. Will not return errors related to
 * incomplete missing operands (formula, pr, or pr.root) or operator,
 * so the existence of these must be checked before use.

 * @param operand1Model the left hand side operand's model
 * @param operator the operator
 * @param operand2Model the right hand side operand's model
 * @returns an array of string errors
 */
export function getLineErrorsForGuidedLine(
    operand1Model: FormulaModel,
    operator: ComparisonOperator | null,
    operand2Model: FormulaModel,
): string[] {
    const lineErrors: string[] = [];

    if (
        operand1Model.pr &&
        operand1Model.pr.root &&
        operand1Model.valid &&
        operand2Model.pr &&
        operand2Model.pr.root &&
        operand2Model.valid &&
        operator
    ) {
        const rootNode: BinaryOperationNode = {
            errors: [],
            warnings: [],
            path: 'not needed for analyzeNode',
            lhs: operand1Model.pr.root,
            rhs: operand2Model.pr.root,
            operator,
            ranges: {
                lhs: operand1Model.pr.root.ranges.node,
                rhs: operand2Model.pr.root.ranges.node,
                node: { start: 0, end: 0 },
                operator: { start: 0, end: 0 },
            },
            type: AstNodeType.binaryOperation,
            valid: null,
        };
        analyzeNode(rootNode, { functionDefs: [] });

        for (const node of iterateAst(rootNode)) {
            lineErrors.push(...node.errors.map((x) => x.text));
        }
    } else {
        if (operand1Model.pr) lineErrors.push(...getLineErrorsForParserResult(operand1Model.pr));
        if (operand2Model.pr) lineErrors.push(...getLineErrorsForParserResult(operand2Model.pr));
    }

    return lineErrors;
}

/**
 * Generates errors for a formula line by pulling errors from nodes, including
 * incompatible return type error generated by filter editor analyzers. Will not
 * return errors related to incomplete missing formula, pr, or pr.root, so the
 * existence of these must be checked before use.
 * @param conditionModel the condition model
 * @returns an array of string errors
 */
export function getLineErrorsForFormulaLine(conditionModel: FormulaModel): string[] {
    if (!conditionModel.pr) return [];
    return getLineErrorsForParserResult(conditionModel.pr);
}
