import type { AppAction } from 'src/store/actions';

export interface Condition {
    field: string;
    expected: unknown;
    nextStep: string;
    ribbonDefault?: boolean;
}

export interface Step {
    label?: string;
    name: string;
    schema?: string;
    nextStep?: string;
    ribbonStep: string;
    conditions?: Condition[];
}

export interface FlowConfigState {
    flow: string;
    steps?: Step[];
}

const initialState: FlowConfigState = {
    flow: '',
};

export interface HydrateFlowAction {
    type: 'accountSetup::flowConfig::hydrateFlow';
    payload: FlowConfigState;
}
export const hydrateFlow = ({ flow, steps }: FlowConfigState): HydrateFlowAction => ({
    type: 'accountSetup::flowConfig::hydrateFlow',
    payload: {
        flow,
        steps,
    },
});

export const accountSetupFlowConfigReducer = (state = initialState, action: AppAction): FlowConfigState => {
    switch (action.type) {
        case 'accountSetup::flowConfig::hydrateFlow': {
            return {
                ...state,
                flow: action.payload.flow,
                steps: action.payload.steps,
            };
        }
    }
    return state;
};
