import { accountSetupFlowConfigReducer } from './flowConfig/flowConfig.slice';
import type { HydrateFlowAction } from './flowConfig/flowConfig.slice';
import { combineReducers } from 'redux';
import { accountSetupFormReducer } from 'src/features/account-setup/store/formSlice';
import type { AccountFormActions } from 'src/features/account-setup/store/formSlice';

export type AccountCreationActions = HydrateFlowAction | AccountFormActions;

export const accountSetup = combineReducers({
    data: accountSetupFormReducer,
    flowConfig: accountSetupFlowConfigReducer,
});
