import Debug from 'debug';
import { inject, injectable } from 'src/features/ioc';
import { PERSISTED_CURRENT_USER } from 'src/features/local-storage';
import { container } from 'src/ioc/StaticContainer';
import type { IsomorphicBindings } from 'src/ioc/types';
import { localforage } from 'src/lib/serialization/localForage';
import type { RootState } from 'src/store';
import { getUser } from 'src/store/selectors';

const debug = Debug('ta:feat:electron-renderer:syncToExternalsService');

/**
 * Syncs various data from the store to external services
 */
@injectable()
export class SyncToExternalsServiceImpl {
    constructor(@inject('Store') private readonly store: IsomorphicBindings['Store']) {
        this.store
            .subscribeSelector(getUser, { leading: true })
            .handle((userDetails) => this.syncUserDetails(userDetails));
    }

    private syncUserDetails(userDetails: RootState['auth']['user']) {
        debug(userDetails);

        try {
            localforage.setItem(PERSISTED_CURRENT_USER, userDetails);
            container.getOptional('DatadogService')?.setUserDetails(userDetails);
        } catch (e) {
            debug('Failed to sync user details', e);
        }
    }
}
