import { REMOTE_SLICE_ACTION } from '../lib/createRemoteSlice';
import { workspaceSettings } from '../overlays/workspaceSettings';
import type { SagaContext } from '../types';
import { all, getContext, takeEvery } from 'redux-saga/effects';

function* onOverlayFocus() {
    const getOverlaysService: SagaContext['overlaysServiceProvider'] = yield getContext('overlaysServiceProvider');
    const overlaysService = getOverlaysService();
    if (overlaysService) {
        overlaysService.focus();
    }
}

export function* overlaysSagas() {
    yield all([takeEvery([`${REMOTE_SLICE_ACTION}:${workspaceSettings.name}:showMenu`], onOverlayFocus)]);
}
