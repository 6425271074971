export const colors = [
    'bg-yellow-500/50',
    'bg-sky-500/50',
    'bg-emerald-500/50',
    'bg-cyan-500/50',
    'bg-pink-500/50',
    'bg-purple-500/50',
];

// Storing these as hard-coded colors for now, in the future these could be in Tailwind config
export const SYNTAX_CLASSNAMES = {
    text: 'text-[#D5FBE7]',
    'text-muted': 'text-[#677E72]',
    token: 'text-[#86B0B8]',
    indicator: 'text-[#B6D199]',
    indicatorToken: 'text-[#60C9BC]',
    string: 'text-[#b0e18c]',
    duration: 'text-[#2cb7d2]',
    pointInTimeOrTimeframe: 'text-[#99d7e3]',
    quantity: 'text-[#1a90c0]',
};
