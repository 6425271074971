import type { AppAction } from '../../../../actions';
import type {
    ExecutionsWidgetViewModel,
    IfThenBuilderWidgetViewModel,
    MessagesWidgetViewModel,
    NewsAndEventsWidgetViewModel,
    OrdersWidgetViewModel,
    PositionsWidgetViewModel,
    SearchAlphaWidgetViewModel,
    StrategyWidgetViewModel,
    WidgetTabViewModel,
    WidgetViewModel,
} from '../../../../types';
import { executionsReducer } from './executions';
import { ifThenReducer } from './ifThenReducer';
import { lensReducer } from './lens';
import { locatesReducer } from './locates';
import { messagesReducer } from './messages';
import { newsReducer } from './news';
import { orderBlotterReducer } from './orderBlotter';
import { orderEntryReducer } from './orderEntry';
import { ordersReducer } from './orders';
import { positionsReducer } from './positions';
import { resultsReducer } from './results';
import { searchAlphaReducer } from './searchAlphaReducer';
import { timeSeriesReducer } from './timeSeries';
import { watchlistReducer } from './watchlist';
import type {
    TimeSeriesWidgetModel,
    AlphaLensWidgetModel,
    OrderEntryWidgetModel,
    LocatesWidgetModel,
    OrderBlotterWidgetModel,
} from 'src/contracts/workspace';
import type { UpdateTabNameAction } from 'src/store/actions/tab';
import type { OnTabChannelChangeEvent, UpdateWidgetAction } from 'src/store/actions/widgetAndChannel';

type TargetedWidgetUpdateAction = UpdateTabNameAction | UpdateWidgetAction | OnTabChannelChangeEvent;

const targetedWidgetReducer = (state: WidgetTabViewModel, action: TargetedWidgetUpdateAction): WidgetTabViewModel => {
    switch (action.type) {
        case 'updateTabName':
            return {
                ...state,
                name: action.tabName,
            };

        case 'updateWidget':
            return {
                ...state,
                ...(action.tabUpdates ? action.tabUpdates : {}),
                widget: { ...state.widget, ...action.widgetUpdates } as WidgetViewModel,
            };

        case 'onTabChannelChange':
            return {
                ...state,
                channelId: action.channelId ? action.channelId : null,
                widget: { ...state.widget, channelId: action.channelId },
            };

        default:
            return state;
    }
};

export const tabIdReducer = (state: WidgetTabViewModel, action: AppAction): WidgetTabViewModel => {
    switch (action.type) {
        case 'updateTabName':
        case 'updateWidget':
        case 'onTabChannelChange':
            if (action.tabId !== state.id) {
                return state;
            }

            return targetedWidgetReducer(state, action);

        case 'addTagToWatchList':
        case 'removeTagFromWatchList':
        case 'setSelectedUniverse':
            if (action.tabId !== state.id) {
                return state;
            }

            if (state.widget.type !== 'watchlist') {
                return state;
            }

            return watchlistReducer(state as WidgetTabViewModel<StrategyWidgetViewModel>, action);

        case 'updateAlphaLensSymbol': {
            if (action.tabId !== state.id) {
                return state;
            }

            return lensReducer(state as WidgetTabViewModel<AlphaLensWidgetModel>, action);
        }

        /* ToDo: Merge the two widgets after PO's decide that's we're ready for that thing */

        case 'setDisplayingSearch':
        case 'setDisplayingResults':
        case 'setSearchAlphaLastSubmit':
        case 'setSearchAlphaStrategy':
        case 'setSearchAlphaUniverse':
        case 'storeSplitPercentage': {
            if (action.tabId !== state.id) {
                return state;
            }

            return searchAlphaReducer(state as WidgetTabViewModel<SearchAlphaWidgetViewModel>, action);
        }

        case 'updateWidgetStrategy': {
            if (action.tabId !== state.id) {
                return state;
            }

            return ifThenReducer(state as WidgetTabViewModel<IfThenBuilderWidgetViewModel>, action);
        }

        case 'updateOrderEntryAccount':
        case 'updateOrderEntrySymbol': {
            if (action.tabId !== state.id) {
                return state;
            }

            return orderEntryReducer(state as WidgetTabViewModel<OrderEntryWidgetModel>, action);
        }

        case 'updateOrderBlotterAccount':
        case 'updateOrderBlotterSymbol': {
            if (action.tabId !== state.id) {
                return state;
            }

            return orderBlotterReducer(state as WidgetTabViewModel<OrderBlotterWidgetModel>, action);
        }

        case 'updateTimeSeriesSymbol': {
            if (action.tabId !== state.id) {
                return state;
            }

            return timeSeriesReducer(state as WidgetTabViewModel<TimeSeriesWidgetModel>, action);
        }

        case 'updateLocatesAccount':
        case 'updateLocatesSymbol': {
            if (action.tabId !== state.id) {
                return state;
            }

            return locatesReducer(state as WidgetTabViewModel<LocatesWidgetModel>, action);
        }

        case 'updatePositionsAccount':
        case 'updatePositionsSymbolFilter': {
            if (action.tabId !== state.id) {
                return state;
            }

            return positionsReducer(state as WidgetTabViewModel<PositionsWidgetViewModel>, action);
        }

        case 'updateOrdersAccount':
        case 'updateOrdersSymbolFilter': {
            if (action.tabId !== state.id) {
                return state;
            }

            return ordersReducer(state as WidgetTabViewModel<OrdersWidgetViewModel>, action);
        }

        case 'updateExecutionsAccount':
        case 'updateExecutionsSymbolFilter': {
            if (action.tabId !== state.id) {
                return state;
            }

            return executionsReducer(state as WidgetTabViewModel<ExecutionsWidgetViewModel>, action);
        }

        case 'updateMessagesAccount':
        case 'updateMessagesSymbolFilter': {
            if (action.tabId !== state.id) {
                return state;
            }

            return messagesReducer(state as WidgetTabViewModel<MessagesWidgetViewModel>, action);
        }

        case 'setResultsStrategy': {
            if (action.tabId !== state.id) {
                return state;
            }

            return resultsReducer(state as WidgetTabViewModel<StrategyWidgetViewModel>, action);
        }

        case 'userSetNewsUniverse':
        case 'userSetNewsStrategy': {
            if (action.tabId !== state.id) {
                return state;
            }

            return newsReducer(state as WidgetTabViewModel<NewsAndEventsWidgetViewModel>, action);
        }
        default:
            return state;
    }
};
