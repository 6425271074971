import { io } from 'socket.io-client';
import { Decoder, Encoder } from 'socket.io-parser';
import { appConfig } from 'src/config';
import { container } from 'src/ioc/StaticContainer';
import { reviver } from 'src/lib/serializer';

const log = container.get('Logger').getSubLogger({ name: 'push' });

export const CONNECTION_OPTIONS = {
    'force new connection': true,
    timeout: 10000,
    transports: ['websocket'],
    parser: {
        Encoder: Encoder,
        Decoder: class extends Decoder {
            constructor() {
                super(reviver);
            }
        },
    },
};

export const pushServiceSocket = io(appConfig.api, CONNECTION_OPTIONS);
// TODO: Do not use window object to detect browser/native, use `isNative`
if (typeof window !== 'undefined') (window as any)['socket'] = pushServiceSocket;

pushServiceSocket.on('connect', () => {
    pushServiceSocket.on('auth.failed', (payload: any) => {
        log.warn({ message: 'Authentication to platform socket.io failed', payload });
    });
    pushServiceSocket.on('auth.success', (payload: any) => {
        log.info({ message: 'Authentication to platform socket.io succeeded', payload });
    });
});

export const setAccessToken = (accessToken: string) => {
    log.info({ message: 'Authenticating with socket.io', accessToken });
    pushServiceSocket.emit('auth', accessToken);
};
