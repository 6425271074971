import type { ResourceQuery } from '../contracts/resource-query';

export const processQuery = (query: ResourceQuery): string => {
    const { filter, search, limit, offset, sortBy, includeMeta, includeDiscarded, includeHidden } = query;
    if (!filter && !search && !limit && offset && sortBy && !includeMeta && !includeDiscarded && !includeHidden) {
        return '';
    }

    let finalQuery = '?';
    if (filter) finalQuery += `&$filter=${encodeURIComponent(filter)}`;
    if (search) finalQuery += `&$search=${encodeURIComponent(search)}`;
    if (sortBy) finalQuery += `&$orderby=${sortBy}`;
    if (limit) finalQuery += `&$top=${limit}`;
    if (offset) finalQuery += `&$skip=${offset}`;
    if (includeMeta) finalQuery += `&$count=${includeMeta}`;
    if (includeDiscarded) finalQuery += `&includeDiscarded=${includeDiscarded}`;
    if (includeHidden) finalQuery += `&includeHidden=${includeHidden}`;

    return finalQuery;
};

export function getInitialSettings(
    filter = '',
    sortBy = '',
    search = '',
    offset = 0,
    limit = 200,
    includeDiscarded?: boolean,
    includeHidden?: boolean,
): ResourceQuery {
    return {
        filter,
        search,
        sortBy,
        limit,
        offset,
        includeMeta: true,
        includeDiscarded,
        includeHidden,
    };
}
