import type { AppAction } from '../actions';
import type { SoundAlert } from '../types/locates';
import type { UploadableLocateProvider } from '@thinkalpha/common/contracts/locates/inventory.js';
import type { LocateOrder } from '@thinkalpha/common/contracts/locates/orders.js';
import type { LocateAccount } from '@thinkalpha/common/contracts/locates/platforms.js';
import type { LocateProvider } from '@thinkalpha/common/contracts/locates/providers.js';
import type { WebSocketStatus } from '@thinkalpha/common/push/socket.js';
import type { LocatePreferences } from 'src/contracts/user-preference';

export interface StoredLocateOrder extends LocateOrder {
    isRemoved?: boolean; // if the order should be removed then it will not be shown in the orders table
}

export interface LocatesState {
    isLocatesBootstrapComplete: boolean;
    isAdmin: boolean;
    providers: LocateProvider[];
    uploadableProviders: UploadableLocateProvider[];
    accounts: LocateAccount[];
    locatePreferences: LocatePreferences | null;
    status: WebSocketStatus | 'uninitialized';
    orders: StoredLocateOrder[];
    enabledSoundAlerts: SoundAlert[];
    shouldExplodeLocates: boolean; // when bootstrap fails this will be true
}

const initialState: LocatesState = {
    isLocatesBootstrapComplete: false,
    isAdmin: false,
    providers: [],
    uploadableProviders: [],
    accounts: [],
    locatePreferences: null,
    status: 'uninitialized',
    orders: [],
    enabledSoundAlerts: [],
    shouldExplodeLocates: false,
};

export const locates = (state = initialState, action: AppAction): LocatesState => {
    switch (action.type) {
        case 'locates::set-admin':
            return {
                ...state,
                isAdmin: action.isAdmin,
            };
        case 'locates::setProviders':
            return {
                ...state,
                providers: action.providers,
            };
        case 'locates::setUploadableProviders':
            return {
                ...state,
                uploadableProviders: action.uploadableProviders,
            };
        case 'locates::setLocatePreferences':
            return {
                ...state,
                locatePreferences: action.locatePreferences,
            };
        case 'locates::setBootstrapComplete':
            return {
                ...state,
                isLocatesBootstrapComplete: action.isLocatesBootstrapComplete,
            };
        case 'masq::access-token::set': {
            return { ...initialState };
        }
        case 'locates::setAccounts':
            return {
                ...state,
                accounts: action.accounts,
            };
        case 'locates::setStatus':
            return {
                ...state,
                status: action.status,
            };
        case 'locates::setOrders':
            return {
                ...state,
                orders: action.orders,
            };
        case 'locates::setEnabledSoundAlerts':
            return {
                ...state,
                enabledSoundAlerts: action.enabledSoundAlerts,
            };
        case 'locates::setShouldExplodeLocates':
            return {
                ...state,
                shouldExplodeLocates: action.shouldExplodeLocates,
            };
        case 'locates::reset':
            return initialState;
    }
    return state;
};
